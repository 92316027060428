import React from "react"
import Sidebar from "../layout/sidebar"

const NavigationSidebar = ({ children }) => {
  return (
    <Sidebar
      links={[
        { name: "ИНФОРМАЦИЯ О ФОНДЕ", slug: "/about/" },
        { name: "ОРГАНЫ УПРАВЛЕНИЯ", slug: "/about/administration/" },
        { name: "НОРМАТИВНЫЕ ДОКУМЕНТЫ", slug: "/about/accounting/" },
        { name: "ОТЧЕТНЫЕ ДОКУМЕНТЫ", slug: "/about/regulations/" },
        {
          name: "СХЕМА ВЗАИМОДЕЙСТВИЯ ПРИ СТРОИТЕЛЬСТВЕ",
          slug: "/about/scheme/",
        },
      ]}
    >
      {children}
    </Sidebar>
  )
}

export default NavigationSidebar
