import React from "react"
import Sidebar from "../../components/aboutPage/sidebar"
import styled from "@emotion/styled"
import useAbout from "../../hooks/use-about"
import { Helmet } from "react-helmet"

const AboutWrapper = styled.div`
  font-size: 0.9rem;
`

const About = props => {
  const data = useAbout()
  return (
    <Sidebar>
      <Helmet>
        <title>Информация о фонде</title>
        <meta
          name="description"
          content="Информация о фонде, Фонд защиты прав граждан -  участников строительства ПК"
        />
      </Helmet>
      <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
      <AboutWrapper>
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
      </AboutWrapper>
    </Sidebar>
  )
}

export default About
