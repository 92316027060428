import React from "react"
import Layout from "./"
import Row from "./row"
import { Link } from "gatsby"
import styled from "@emotion/styled"

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }

const ActiveLink = props => <Link {...props} activeClassName="current-page" />

const NavLink = styled(ActiveLink)`
  display: block;
  color: ${props => props.theme.colors.navLink};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.75rem 0;
  text-decoration: none;
  text-transform: uppercase;

  &.current-page {
    color: ${props => props.theme.colors.activeLink};
  }

  &:hover {
    color: ${props => props.theme.colors.hoverLink};
  }

  ${props => props.theme.tablet} {
    text-align: center;
    padding: 1rem 0;
    border-bottom: 1px solid ${props => props.theme.colors.line};
  }
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    flex-direction: row;
  }
  ${props => props.theme.tablet} {
    ul {
      padding-inline-start: 1.2rem;
    }
    margin-top: -1.5rem;
  }
`

const NavigationWrapper = styled.div`
  flex-basis: 30%;
  padding-right: 3rem;
  ${props => props.theme.tablet} {
    padding-bottom: 2rem;
    padding-right: 0rem;
  }
`

const Navigation = styled.div`
  position: sticky;
  top: 2rem;
`

const ContentWrapper = styled.div`
  flex-basis: 70%;
  line-height: 1.7;
  p {
    padding-bottom: 1rem;
  }
  h1 {
    padding-bottom: 1rem;
  }
  ${props => props.theme.tablet} {
    h1 {
      font-size: 1.1rem;
    }
  }
`

const Sidebar = ({ children, links }) => {
  const navigation = links.map(link => (
    <NavLink key={link.slug} to={link.slug}>
      {link.name}
    </NavLink>
  ))

  return (
    <Layout>
      <Row margin="1rem auto">
        <PageWrapper>
          <NavigationWrapper>
            <Navigation>{navigation}</Navigation>
          </NavigationWrapper>
          <ContentWrapper>{children}</ContentWrapper>
        </PageWrapper>
      </Row>
    </Layout>
  )
}

export default Sidebar
